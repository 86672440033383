<template>
    <div class="goodsInfoDetail">
        <div class="top">
            <div class="top-tiele">
                {{ examName }}
            </div>
            <div class="top-btn">
                <el-button type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="middle">
            <el-scrollbar class="content-body">
                <div class="g-info-title" style="border-bottom: 1px solid #EEEEEE">
                    <div class="g-text">商品信息</div>
                    <div class="g-item">市场价</div>
                    <div class="g-item">销售价</div>
                </div>
                <div class="g-content-fillin" v-for="(item, index) in goodsList" :key="index">
                    <div class="g-content">
                        <div class="t-ginfo">
                            <img style="width: 60px; height: 60px; padding-top: 6px" :src="hosturl +item.good_images" alt="">
                            <el-tooltip placement="top"
                                :content="item.good_title">
                                <div class="g-title">{{  item.good_title }}</div>
                            </el-tooltip>
                        </div>
                        <div class="t-item">{{ item.market_price }}</div>
                        <div class="t-item">{{ item.sales_price }}</div>
                    </div>
                    <div class="g-fillin">
                        <div class="item">
                            <div class="item-text">
                                <img src="../../assets/images/student/subordinate.png" alt="">
                                <div style="margin-left: 20px">请写出搜索指数排名前{{ item.search.rank }}的关键词：</div>
                            </div>
                            <div class="item-input">
                                <div>{{ Array.isArray(item.search) ? '学生未作答' : item.search.answer.join('、') }}</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-text">
                                <img src="../../assets/images/student/subordinate.png" alt="">
                                <div style="margin-left: 20px">请写出点击指数排名前{{ item.click.rank }}的关键词：</div>
                            </div>
                            <div class="item-input">
                                <div>{{ Array.isArray(item.click) ? '学生未作答' : item.click.answer.join('、') }}</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-text">
                                <img src="../../assets/images/student/subordinate.png" alt="">
                                <div style="margin-left: 20px">请写出点击率排名前{{ item.click_rate.rank }}的关键词：</div>
                            </div>
                            <div class="item-input">
                                <div>{{ Array.isArray(item.click_rate) ? '学生未作答' : item.click_rate.answer.join('、')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import {teacheropGoodsInfoCollectDetail, teacheropTeacherScoreDetail} from '@/utils/apis'
export default {
    name: 'GoodsInfoDetail',
    data() {
        return {
            examName: '',
            goodsList: [],
            hosturl: localStorage.getItem('hosturl'),
        }
    },
    mounted() {
        this.getGoodsInfoList();
    },
    methods: {
        getGoodsInfoList() {
            let param = {
                    exam_id: this.$route.query.exam_id,
                    student_id: this.$route.query.student_id
                }
                teacheropGoodsInfoCollectDetail(param).then((res) => {
                    if (res.data) {
                        this.examName = res.data.exam_name;
                        if (this.$route.query.status == '提交答案') {
                            this.goodsList = res.data.student_answer;
                        } else {
                            this.goodsList = res.data.correct_answer;
                        }
                    }
                })
        },
        goBack() {
            if (localStorage.getItem('role') == 6){ 
                this.$router.push({
                    path: '/examManage/examData/goodsInfo',
                    query: {
                        examId: this.$route.query.exam_id,
                        id: this.$route.query.student_id,
                        isGrade: this.$route.query.isGrade,
                        type: this.$route.query.type,
                        method: this.$route.query.method
                    }
                });
            };
            if (localStorage.getItem('role') == 4){ 
                this.$router.push({
                    path: '/judgesManage/judgesData/goodsInfo',
                    query: {
                        examId: this.$route.query.exam_id,
                        id: this.$route.query.student_id,
                        isGrade: this.$route.query.isGrade,
                        type: this.$route.query.type,
                        method: this.$route.query.method
                    }
                });
            };
        }
    },
}
</script>

<style scoped lang="scss">
    .goodsInfoDetail {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 20px;
        margin-right: 20px;
        .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 50px;
            border-bottom: 1px solid #EEE;
            .top-tiele {
                font-size: 16px;
                color: #333;
                line-height: 50px;
            }
        }
        .middle {
            display: flex;
            flex-direction: column;
            height: 90%;
            margin-top: 40px;
            .content-body {
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                    // padding-bottom: 18px;
                    .el-scrollbar__view {
                        height: 100%;
                    }
                }
            }
            .g-info-title {
                display: flex;
                flex-direction: row;
                height: 50px;
                font-size: 16px;
                color: #333;
                line-height: 50px;
                background: #F6F6F6;
                .g-text {
                    flex: 2.8;
                    margin-left: 39px;
                }
                .g-item {
                    flex: 1;
                }
            }
            .g-content-fillin {
                display: flex;
                flex-direction: column;
                .g-content {
                    display: flex;
                    flex-direction: row;
                    height: 70px;
                    background: #FBFBFD ;
                    border-bottom: 1px solid #EEEEEE;
                    .t-ginfo {
                        flex: 2.8;
                        display: flex;
                        flex-direction: row;
                        height: 70px;
                        margin-left: 39px;
                        font-size: 16px;
                        color: #333333;
                    }
                    .t-item {
                        flex: 1;
                        height: 70px;
                        font-size: 16px;
                        color: rgba(51, 51, 51, .8);
                        line-height: 70px;
                    }
                    .g-title {
                        width: 272px;
                        height: 44px;
                        font-size: 16px;
                        color: #333;
                        margin-left: 8px;
                        margin-top: 12px;
                        display:-webkit-box;
                        text-overflow:ellipsis;
                        overflow:hidden;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient:vertical;
                    }
                    .t-parse {
                        text-align: center;
                    }
                }
                .g-fillin {
                    display: flex;
                    flex-direction: column;
                    .item {
                        display: flex;
                        flex-direction: row;
                        border-bottom: 1px solid #EEEEEE;
                    }
                    .item-text {
                        flex: 1;
                        height: 22px;
                        display: flex;
                        flex-direction: row;
                        margin: 24px 0 24px 63px;
                    }
                    .item-input {
                        flex: 3.5;
                        font-size: 14px;
                        color: #333;
                        margin: 24px 27px 15px 0;
                    }
                }
            }
        }
    }
</style>
